import React, { useContext, useRef } from "react";
import CaptionsEn from "file-loader!../../assets/audio-en.vtt";
import CaptionsEs from "file-loader!../../assets/audio-es.vtt";
import { ThemeContext } from "../themes/theme-context";

const AudioPlayer = () => {
	const dataRef = useRef(null);
	const { audioPlaying, audioData } = useContext(ThemeContext);

	if (dataRef.current) {
		if (audioPlaying) {
			dataRef.current.play();
		} else {
			dataRef.current.pause();
		}
	}

	return (
		audioData && (
			<audio id="audio" loop ref={dataRef}>
				<source src={audioData.url} type={audioData.contentType} />
				<track srcLang="en" kind="captions" src={CaptionsEn} />
				<track srcLang="es" kind="captions" src={CaptionsEs} />
				Your browser doesn't support the HTML5 Audio element
			</audio>
		)
	);
};

export default AudioPlayer;
